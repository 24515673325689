<template>
  <div class="bitts-simple-collapse" @click.stop="toggleCollapse('header')">
    <div class="bitts-simple-collapse__header">
      <div class="flex items-center">
        <div class="mr-8 h-20">
          <slot name="prefix">
            <FontAwesomeIcon
              v-if="!hideChevron && chevronPlacement === 'left'"
              :icon="['fak', 'chevron-down']"
              :class="chevronClass"
              class="bitts-simple-collapse__prefix-chevron"
              style="height: 20px; width: 20px"
            />
          </slot>
        </div>
        <slot name="title">
          <span class="bitts-simple-collapse__title" :class="[textClass]">
            {{ title }}
          </span>
        </slot>
      </div>
      <slot name="suffix">
        <FontAwesomeIcon
          v-if="!hideChevron && chevronPlacement === 'right'"
          :icon="['fak', 'chevron-down']"
          :class="chevronClass"
          class="bitts-simple-collapse__suffix-chevron"
          style="height: 20px; width: 20px"
          @click.stop="toggleCollapse('chevron')"
        />
      </slot>
    </div>
    <Transition name="collapse">
      <div v-if="isOpen" class="bitts-simple-collapse__content-wrapper">
        <div class="bitts-simple-collapse__content">
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

export type CollapseProps = {
  hideChevron?: boolean;
  title?: string;
  speed?: 'slow' | 'medium' | 'fast';
  chevronPlacement?: 'left' | 'right';
  variant?: 'light' | 'dark';
};
const {
  hideChevron,
  title,
  speed = 'medium',
  chevronPlacement = 'left',
  variant = 'light',
} = defineProps<CollapseProps>();

const emit =
  defineEmits<(e: 'clicked-toggle', source: 'header' | 'chevron') => void>();

const isOpen = defineModel<boolean>('isOpen');

const toggleCollapse = (source: 'header' | 'chevron') => {
  isOpen.value = !isOpen.value;
  emit('clicked-toggle', source);
};

const transitionDuration = computed(() => {
  return {
    slow: '750ms',
    fast: '200ms',
    medium: '300ms',
  }[speed ?? 'medium'];
});

const chevronClass = computed(() => [
  'ml-auto',
  isOpen.value ? 'rotate-180' : '',
  variant === 'dark' ? 'text-neutral-300' : 'text-neutral-text',
]);

const textClass = computed(() =>
  variant === 'dark' ? 'text-neutral-300' : 'text-neutral-text',
);
</script>

<style scoped lang="pcss">
.collapse-enter-active,
.collapse-leave-active {
  @apply transition-all ease-in-out overflow-hidden;
  /* use this for dynamic height */
  transition-duration: v-bind(transitionDuration);
}

.collapse-enter-from,
.collapse-leave-to {
  @apply max-h-0 opacity-0;
}

.collapse-enter-to,
.collapse-leave-from {
  @apply max-h-[720px] opacity-100;
}
.bitts-simple-collapse {
  @apply rounded-lg w-full;
}

.bitts-simple-collapse__header {
  @apply cursor-pointer flex items-center justify-between font-bold w-full h-20;
}

.bitts-simple-collapse__content-wrapper {
  @apply overflow-hidden;
}

.bitts-simple-collapse__prefix-chevron,
.bitts-simple-collapse__suffix-chevron {
  @apply transition-transform duration-200;
}
</style>
